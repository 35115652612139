import { useTracking } from './index';
import { CommunityAppEnum } from 'generated/types';


export const usePageTracking = () => {
  const { trackEvent } = useTracking();
  return {
    trackPage: (path?: string) => trackEvent({ name: 'page_visit', path: path })
  };
};


export const useCommunityLandingPageTracking = () => {
  const { trackEvent } = useTracking();
  const trackCommunityLandingPage = () =>
    trackEvent({
      name: 'page_visit',
      properties: {
        pageName: 'community_landing'
      }
    });


  return {
    trackCommunityLandingPage
  };
};

export const useJoinCommunityFormTracking = () => {
  const { trackEvent } = useTracking();
  const trackJoinCommunityForm = () => {
    trackEvent({
      name: 'landing_join_form_submitted',
      properties: {
        pageName: 'community_landing_form'
      }
    });
  };
  return {
    trackJoinCommunityForm
  };
};

export const useJoinCommunityTracking = () => {
  const { trackEvent } = useTracking();
  const trackJoinCommunity = (location: 'cta' | 'header') => {
    trackEvent({
      name: 'landing_join_btn_clicked',
      properties: {
        pageName: 'community_landing',
        location
      }
    });
  };
  return {
    trackJoinCommunity
  };
};

export const useCreateCommunityTracking = () => {
  const { trackEvent } = useTracking();

  const trackCreateCommunityPage = () => {
    trackEvent({
      name: 'page_visit',
      properties: {
        pageName: 'create_community'
      }
    });
  }

  const trackSelectCommunityApp = (app: CommunityAppEnum) => {
    trackEvent({
      name: 'select_community_app',
      properties: {
        app
      }
    });
  }

  return {
    trackCreateCommunityPage,
    trackSelectCommunityApp
  };
}

export const useSelectCommunityGoalTracking = () => {
  const { trackEvent } = useTracking();

  const trackSelectCommunityGoalPage = () => {
    trackEvent({
      name: 'page_visit',
      properties: {
        pageName: 'select_community_goal'
      }
    });
  }

  const trackSelectCommunityGoal = (goal: 'landing' | 'connect') => {
    trackEvent({
      name: 'select_community_goal',
      properties: {
        goal
      }
    });
  };
  return {
    trackSelectCommunityGoalPage,
    trackSelectCommunityGoal
  };
}
