import { CombinedError } from 'urql';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldPath } from 'react-hook-form/dist/types/path';

export const useFormErrorHandling = () => {
  /*
  * @param backendFields - key - name on backend, value - name on FE
  */
  const handleErrors = <T extends FieldValues>(
    error: CombinedError,
    setError: UseFormSetError<T>,
    backendFields: {
      [key: string]: FieldPath<FieldValues>
    } = {}
  ) => {
    type fieldPath = FieldPath<T>;

    backendFields['__all__'] = 'root'; // __all__ is a default field name for the root error
    for (const [, err] of error.graphQLErrors.entries()) {
      let fieldName = err.extensions?.field;
      let backendFieldName = (fieldName || '__all__') as string;
      let field = backendFields[backendFieldName] || fieldName || 'root';
      setError(field as fieldPath, {
        type: field == 'root' ? '400' : 'custom',
        message: err.message
      });
    }
  };
  return {
    handleErrors
  };
};
