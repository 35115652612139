import React, { Context, createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { ToasterToast, useToast } from 'components/ui/use-toast';

interface AlertContextType {
  success: (text: string, limit?: number) => void;
  error: (text: string, limit?: number) => void;
  toasts: ToasterToast[];
};

const defaultValues: AlertContextType = {
  success: () => void 0,
  error: () => void 0,
  toasts: [],
};

const AlertContext: Context<AlertContextType> = createContext(defaultValues);

export const AlertContextProvider = ({ children }: { children: ReactNode }) => {
  const { toast, toasts } = useToast();

  const success = useCallback((text: string, limit?: number) => {
    toast({
      variant: 'success',
      title: text,
      limit,
    });
  }, [toast]);
  const error = useCallback((text: string, limit?: number) => {
    toast({
      variant: 'destructive',
      title: text,
      limit,
    });
  }, [toast]);

  const value = useMemo(() => ({
    success,
    error,
    toasts,
  }), [error, success, toasts]);

  return (
    <AlertContext.Provider
      value={value}
    >
      {children}
    </AlertContext.Provider>
  );
};


export function useAlerts() {
  return useContext(AlertContext);
}
